import { Box, Stack } from "@mui/material";

import { WorkplaceReferralsBanner } from "./WorkplaceReferralsBanner";
import { WorkerReferralBanner } from "../../appV2/Accounts/WorkerReferrals/components/WorkerReferralBanner";

export interface HorizontalReferralBannersProps {
  isWorkerToWorkplaceReferralsEnabled: boolean;
  referAndEarnBannerIsVisible: boolean;
  workerBannerIsShownFirst: boolean;
}

export function HorizontalReferralBanners(props: HorizontalReferralBannersProps) {
  const {
    isWorkerToWorkplaceReferralsEnabled,
    referAndEarnBannerIsVisible,
    workerBannerIsShownFirst,
  } = props;

  if (referAndEarnBannerIsVisible && !isWorkerToWorkplaceReferralsEnabled) {
    return (
      <Box padding={1}>
        <WorkerReferralBanner />
      </Box>
    );
  }
  if (isWorkerToWorkplaceReferralsEnabled && !referAndEarnBannerIsVisible) {
    return (
      <Box padding={1}>
        <WorkplaceReferralsBanner />
      </Box>
    );
  }

  const banners = [
    <Box key={1} flexBasis="80%" flexShrink={0}>
      <WorkplaceReferralsBanner />
    </Box>,
    <Box key={0} flexBasis="80%" flexShrink={0}>
      <WorkerReferralBanner isFullHeight />
    </Box>,
  ];
  if (workerBannerIsShownFirst) {
    banners.reverse();
  }

  return (
    <Stack direction="row" justifyContent="space-between" overflow="auto" spacing={2} padding={2}>
      {...banners}
    </Stack>
  );
}
