import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { MyAccount } from "./account";
import { logout } from "../store/session";
import { Store } from "../store/store.model";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

const mapStateToProps = (state: Store) => ({
  isLoggingOut: state.session.isLoggingOut,
  agent: state.session.agent,
});

const AccountPage = connect(mapStateToProps, mapDispatchToProps)(MyAccount);

export { AccountPage };
